import React, { useRef, useState } from 'react';
import { motion, useInView } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import GuideItem from './GuideItem';
import Img1 from 'images/main-page/skrin_mocap_1.webp';
import Img2 from 'images/main-page/skrin_mocap_2.webp';
import Img3 from 'images/main-page/skrin_mocap_3.webp';

const guides = (
  t: any,
): {
  title: string;
  description: string;
  imgSrc: string;
}[] => [
  {
    ...t('hairdresserPage.guide.steps', { returnObjects: true })[0],
    imgSrc: Img1,
  },
  {
    ...t('hairdresserPage.guide.steps', { returnObjects: true })[1],
    imgSrc: Img2,
  },
  {
    ...t('hairdresserPage.guide.steps', { returnObjects: true })[2],
    imgSrc: Img3,
  },
];

const GuideSection = () => {
  const { t } = useTranslation();
  const [currentImg, setCurrentImg] = useState(Img1);
  const { pathname } = useLocation();
  const isBotoxPage = pathname.includes('botox');

  const sectionRef = useRef(null);

  const isSticky = useInView(sectionRef);

  return (
    <section className='main-page-wrapper relative flex w-full max-w-[1440px] flex-col'>
      <h3 className='mx-auto mb-14 max-w-[630px] text-center text-[2.81rem] leading-[4.219rem] xs:text-[2.6rem]'>
        {isBotoxPage
          ? t('hairdresserPage.guide.heading')
          : t('hairdresserPage.guide.heading-hair')}
      </h3>
      <div
        ref={sectionRef}
        className='relative md:flex md:items-stretch md:justify-between'
      >
        <ul className='flex flex-col gap-14 md:max-w-[50%] md:gap-2'>
          {guides(t).map((guide, i) => (
            <GuideItem
              key={`${guide.title}-${i}`}
              order={i + 1}
              setCurrentImg={setCurrentImg}
              {...guide}
            />
          ))}
        </ul>

        <div className='relative hidden pt-96 md:block md:w-[40%]'>
          <motion.img
            key={currentImg}
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            src={currentImg}
            alt='App screenshot'
            loading='lazy'
            className={`${
              isSticky ? 'sticky' : 'relative'
            } top-1/2 -mb-48 aspect-[185/218] h-[450px] -translate-y-1/2 transform object-contain`}
          />
        </div>
      </div>
    </section>
  );
};

export default GuideSection;
