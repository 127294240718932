import React, { useState } from 'react';
import Input from './Input';
import { IconType } from 'react-icons';

type AutocompleteProps = {
  options: string[];
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  overflow?: boolean;
  Icon?: IconType;
  onIconClick?: () => void;
  iconClassName?: string;
  placeholder?: string;
};

const Autocomplete = ({
  options,
  inputValue,
  setInputValue,
  overflow = false,
  Icon,
  onIconClick,
  iconClassName,
  placeholder,
}: AutocompleteProps) => {
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (event.target.value) {
      const filtered = options.filter((option) =>
        option.toLowerCase().includes(event.target.value.toLowerCase()),
      );
      setFilteredOptions(filtered as never[]);
    } else {
      setFilteredOptions([]);
    }
  };

  const handleOptionClick = (option: string) => {
    setInputValue(option);
    setFilteredOptions([]);
  };

  return (
    <div className={`${overflow ? 'relative' : ''}`}>
      <Input
        type='text'
        value={inputValue}
        variant='outline'
        label='Service name'
        placeholder={placeholder}
        onChange={handleInputChange}
        Icon={Icon}
        onIconClick={onIconClick}
        iconClassName={iconClassName}
      />
      {filteredOptions.length > 0 && (
        <ul
          className={`${
            overflow
              ? 'absolute top-full z-10 flex max-h-40 flex-col overflow-y-auto rounded bg-white'
              : 'flex max-h-36 flex-col overflow-y-auto rounded'
          }  py-2 shadow-xl`}
        >
          {filteredOptions.map((option, i) => (
            <li
              className='cursor-pointer px-3 py-2 hover:bg-gray-100'
              key={option + i}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
