import React, { useState, useEffect } from 'react';
import { ActionButtons, Sheet } from 'ui';
import Logo from 'images/icons/logo.svg';
import { useTranslation } from 'react-i18next';

const InstallPrompt = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();

      const prompted = localStorage.getItem('prompted');
      const promptDeclinedDate = localStorage.getItem('promptDeclinedDate');
      const daysSinceDeclined = promptDeclinedDate
        ? (Date.now() - parseInt(promptDeclinedDate, 10)) /
          (1000 * 60 * 60 * 24)
        : null;

      if (
        prompted === 'true' &&
        daysSinceDeclined !== null &&
        daysSinceDeclined < 30
      ) {
        return;
      }

      if (daysSinceDeclined && daysSinceDeclined >= 30) {
        localStorage.removeItem('prompted');
        localStorage.removeItem('promptDeclinedDate');
      }

      setDeferredPrompt(e);
      setShow(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt,
      );
    };
  }, []);

  const handleAddClick = () => {
    setShow(false);
    localStorage.setItem('prompted', 'true');
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          localStorage.removeItem('prompted');
          localStorage.removeItem('promptDeclinedDate');
        } else {
          localStorage.setItem('promptDeclinedDate', Date.now().toString());
        }
        setDeferredPrompt(null);
      });
    }
  };

  const handleNotNowClick = () => {
    setShow(false);
    localStorage.setItem('prompted', 'true');
    localStorage.setItem('promptDeclinedDate', Date.now().toString());
  };

  if (!show) {
    return null;
  }

  return (
    <Sheet
      className='bottom-5 z-20 rounded-[32px] sm:!hidden'
      handleClose={handleNotNowClick}
    >
      <img src={Logo} className='mx-auto' alt='logo' width='35' height='35' />
      <p className='text-center'>{t('install_app.description')}</p>
      <ActionButtons
        saveButtonText={t('install_app.install')}
        cancelButtonText={t('install_app.cancel')}
        handleCancelClick={handleNotNowClick}
        handleSaveClick={handleAddClick}
        wrapperClassName='mt-4'
      />
    </Sheet>
  );
};

export default InstallPrompt;
