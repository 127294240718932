import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FaChevronRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { toggleModal } from 'redux/modalSlice';

import PinIcon from 'images/emoji/pin.png';
import MoneyIcon from 'images/emoji/(Money).svg';
import StarIcon from 'images/emoji/(star).svg';
import DiamondIcon from 'images/emoji/(Verified specialist).svg';
import DurationIcon from 'images/emoji/Hours.svg';
import PortfolioIcon from 'images/emoji/Media.svg';

type SpecialistStatsProps = {
  rank: number;
  reviewsNumber?: number;
  imagesAmount?: number;
  price?: string;
  portfolioUid?: string;
  reviewsUid?: string;
  location?: string;
  isLoading?: boolean;
  duration?: number;
  showLeaveReview?: boolean;
  ableNavigateToMap?: boolean;
};

const icons: { [key: string]: string } = {
  PortfolioIcon,
  StarIcon,
  DiamondIcon,
  PinIcon,
  MoneyIcon,
  DurationIcon,
};

const ExpandedSpecialistStats = ({
  rank,
  reviewsNumber,
  imagesAmount,
  price,
  portfolioUid,
  reviewsUid,
  location,
  isLoading = false,
  duration,
  showLeaveReview,
  ableNavigateToMap = true,
}: SpecialistStatsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { role } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const stats = [
    {
      icon: 'StarIcon',
      title: (
        <>
          {rank === 0
            ? t('order.specialist_no_rating')
            : `${rank} ${t('order.specialist_found.rating')}`}
          {reviewsNumber ? (
            <span className='text-secondary'>{` ${reviewsNumber} ${t(
              'order.specialist_found.reviews',
            )} `}</span>
          ) : (
            ''
          )}
        </>
      ),
      subtitle: (
        <div className='flex'>
          {role === 'client' && showLeaveReview && (
            <span
              className='mr-2 cursor-pointer underline hover:no-underline'
              onClick={() =>
                dispatch(toggleModal({ modalType: 'clientLeaveReview' }))
              }
            >
              {t('order.client.leave_review')}
            </span>
          )}

          {reviewsUid ? (
            <Link
              to={`/reviews/${reviewsUid}`}
              className='underline hover:no-underline'
            >
              {t('order.specialist_found.watch_reviews')}
            </Link>
          ) : (
            ''
          )}
        </div>
      ),
      onClick: () => {
        if (reviewsUid && !showLeaveReview) {
          navigate(`/reviews/${reviewsUid}`);
        }
      },
      condition: true,
      conditionShowChevron:
        // hide chevron if two buttons are shown
        !(reviewsUid && role === 'client' && showLeaveReview),
    },
    {
      icon: 'DiamondIcon',
      title: t('order.specialist_found.verificated_person'),
      subtitle: t('order.specialist_found.telephone_number'),
      condition: true,
    },
    {
      icon: 'DurationIcon',
      title: t('order.duration'),
      subtitle: `${duration} ${t('minutes')}`,
      condition: duration !== undefined,
    },
    {
      icon: 'PortfolioIcon',
      title: t('order.specialist_found.portfolio'),
      subtitle: imagesAmount
        ? `${imagesAmount} ${t('order.specialist_found.images_cnt')}`
        : t('order.specialist_found.no_images'),
      condition: imagesAmount !== undefined,
      conditionShowChevron: !!portfolioUid && !!imagesAmount,
      onClick: () =>
        portfolioUid &&
        imagesAmount &&
        navigate(`/specialist/portfolio/${portfolioUid}`),
    },
    {
      icon: 'PinIcon',
      title: `${location}`,
      subtitle: ableNavigateToMap
        ? t('order.specialist_found.map')
        : t('edit.profile_location'),
      condition: location !== undefined,
      conditionShowChevron: ableNavigateToMap,
      onClick: () => ableNavigateToMap && navigate(`/map`),
    },
    {
      icon: 'MoneyIcon',
      title: `${price}`,
      subtitle: t('order.specialist_found.total_amount'),
      condition: price !== undefined,
    },
  ];

  return (
    <ul className='flex w-full flex-col gap-[13px]'>
      {stats.map(
        (
          { icon, title, subtitle, condition, onClick, conditionShowChevron },
          i,
        ) =>
          condition ? (
            <li
              key={icon + i}
              className={`flex items-center justify-between gap-3 ${
                onClick ? 'cursor-pointer' : 'cursor-default'
              }`}
              onClick={onClick}
            >
              {isLoading ? (
                <div className='aspect-[162/48] h-11 flex-grow animate-pulse bg-gray-400' />
              ) : (
                <>
                  <div className='flex items-center gap-3'>
                    <img
                      src={icons[icon]}
                      alt='icon'
                      height={20}
                      width={20}
                      className='select-none'
                    />
                    <div>
                      <p>{title}</p>
                      {subtitle && (
                        <div className='text-secondary'>{subtitle}</div>
                      )}
                    </div>
                  </div>
                  {conditionShowChevron && (
                    <FaChevronRight className='text-black' />
                  )}
                </>
              )}
            </li>
          ) : null,
      )}
    </ul>
  );
};

export default ExpandedSpecialistStats;
