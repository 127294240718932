import React, { useEffect } from 'react';
import { useApiRequest } from 'hooks/useApiRequest';
import { Button, SectionHeader, Wrapper } from 'ui';
import { BASE_URL } from 'api/api';
import { useAppSelector } from 'hooks/useAppSelector';
import LogoSad from 'images/icons/logo-sad.svg';
import { Link, useNavigate } from 'react-router-dom';
import { Service } from 'utils/types';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setServices } from 'redux/specialistServicesSlice';
import { useTranslation } from 'react-i18next';

const SpecialistServicesPage = () => {
  const { t } = useTranslation();

  const { uid } = useAppSelector((state) => state.user);
  const { services } = useAppSelector((state) => state.specialistServices);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loading, data: fetchedServices } = useApiRequest<Service[]>({
    endpoint: `${BASE_URL}specialists/${uid}/services`,
    destructuringDataType: 'services',
  });

  useEffect(() => {
    if (!loading && fetchedServices && fetchedServices?.length > 0) {
      dispatch(setServices(fetchedServices));
    }
  }, [fetchedServices, dispatch, loading]);

  return (
    <Wrapper flexColumnSpaceBetween>
      <div className='flex flex-col'>
        <SectionHeader title={t('services.name')} />
        {!loading && services?.length === 0 && (
          <div className='flex h-[60vh] flex-col items-center justify-center'>
            <img src={LogoSad} alt='logo' width='68' height='68' />
            <h2 className='text-lg'>No services yet</h2>
            <p className='text-secondary'>Don’t worry, add new</p>
          </div>
        )}
        <ul className='mt-6 flex max-h-[70vh] flex-col gap-3 overflow-y-auto pr-2 sm:max-h-[80vh]'>
          {services?.length > 0 &&
            services?.map((service, i) => (
              <li key={service.stringId + i}>
                <Button
                  className='flex w-full items-center py-3 text-left'
                  onClick={() =>
                    navigate(`/specialist/services/add/${service.stringId}`, {
                      state: { ...service.gssDto, serviceName: service.name },
                    })
                  }
                >
                  {service.iconeURL && (
                    <img
                      src={service.iconeURL}
                      alt={`${service.name} icon`}
                      width={14}
                      height={14}
                      className='mr-4'
                    />
                  )}

                  {service.name}
                </Button>
              </li>
            ))}
          {loading && services?.length === 0 && (
            <li>
              <Button className='w-full py-3 text-left' skeleton>
                Loading
              </Button>
            </li>
          )}
        </ul>
      </div>
      <Link to='/specialist/services/select'>
        <Button className='my-2 w-full py-4' variant='yellow'>
          {t('services.add')}
        </Button>
      </Link>
    </Wrapper>
  );
};

export default SpecialistServicesPage;
