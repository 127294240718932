import React, { useState } from 'react';
import { getTypedStorageItem } from 'utils/storage';
import USIcon from 'images/flags/United States.svg';
import PLIcon from 'images/flags/Poland.svg';
import DEIcon from 'images/flags/Germany.svg';
import UAIcon from 'images/flags/Ukraine.svg';

type LanguageItemProps = {
  handleSelectLanguage: (language: 'en' | 'pl' | 'de' | 'ua') => void;
  language: 'en' | 'pl' | 'de' | 'ua';
};

const languageIcons = {
  ua: UAIcon,
  de: DEIcon,
  en: USIcon,
  pl: PLIcon,
};

const languageNames = {
  en: 'English',
  pl: 'Polski',
  de: 'Deutsch',
  ua: 'Українська',
};

const LanguageItem = ({
  handleSelectLanguage,
  language,
}: LanguageItemProps) => {
  const [currLanguage] = useState(
    getTypedStorageItem('selectedLanguage') || 'en',
  );

  return language !== currLanguage ? (
    <p
      className='flex cursor-pointer items-center gap-3 rounded-xl hover:underline'
      onClick={() => handleSelectLanguage(language)}
    >
      <img
        src={languageIcons[language]}
        alt='language'
        className='h-[13px] w-[19px] rounded-[12%] object-cover'
      />
      {languageNames[language]}
    </p>
  ) : (
    <></>
  );
};

export default LanguageItem;
