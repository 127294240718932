import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useApiRequest } from 'hooks/useApiRequest';
import { useAppSelector } from 'hooks/useAppSelector';
import { Button, SectionHeader, Wrapper } from 'ui';
import { BASE_URL } from 'api/api';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setOrderTime, setTimeSlots } from 'redux/orderTimeSlice';
import { resetSearchOrder } from 'redux/searchOrderSlice';
import { useTranslation } from 'react-i18next';
import { SpecialistWithTimeSlots } from 'utils/types';

type ServiceResponse = {
  catName: string;
  id: 0;
  imgURL: string;
  language: string;
  name: string;
  stringId: string;
  subcatName: string;
};

const ServicePage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedCategoryName, selectedServiceName, selectedSubcategoryName } =
    useAppSelector((state) => state.category);
  const { serviceId } = useAppSelector((state) => state.service);
  const { timeSlots } = useAppSelector((state) => state.orderTime);
  const date = new Date();
  const dateFrom = date.toISOString().split('T')[0];
  const dateTo = new Date(date.setDate(date.getDate() + 30))
    .toISOString()
    .split('T')[0];

  const { data: service } = useApiRequest<ServiceResponse>({
    endpoint: `${BASE_URL}services/info?serviceId=${serviceId}`,
  });

  const { data: specialistTimeSlots, loading } =
    useApiRequest<SpecialistWithTimeSlots>({
      conditionToFetch: !!service?.stringId,
      endpoint: `${BASE_URL}time-slot/by-serviceid/${service?.stringId}/date-range?dateFrom=${dateFrom}&dateTo=${dateTo}`,
    });

  date.setMinutes(date.getMinutes() + 30);

  // Find the nearest time slot that is close to the current time or later
  const nearestTimeSlots = [...timeSlots]
    .filter((timeSlot) => new Date(timeSlot.startTime).getTime() > Date.now())
    .sort(
      (a, b) =>
        new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
    );

  const handleAsapClick = () => {
    dispatch(resetSearchOrder());

    dispatch(
      setOrderTime({
        dateStamp: new Date(nearestTimeSlots[0].startTime).getTime(),
        timeStamp: new Date(nearestTimeSlots[0].startTime).getTime(),
        timeSlotId: nearestTimeSlots[0].id,
      }),
    );

    navigate('/search', { state: service?.stringId });
  };

  useEffect(() => {
    if (specialistTimeSlots) {
      dispatch(setTimeSlots(specialistTimeSlots[0].timeSlots));
    }
  }, [dispatch, specialistTimeSlots]);

  return (
    <Wrapper flexColumnSpaceBetween noHorizontalPadding>
      <div>
        <div className='p-main-padding'>
          <SectionHeader
            title={selectedCategoryName}
            subtitle={`${selectedSubcategoryName}, ${selectedServiceName}`}
          />
        </div>

        {service?.imgURL && !loading ? (
          <img
            src={service?.imgURL}
            alt='service'
            className='my-2 h-[250px] w-full bg-contain object-cover'
          />
        ) : (
          <div className='my-2 h-[300px] w-full animate-pulse rounded bg-secondary' />
        )}
      </div>
      {service?.name && !loading ? (
        <div className='flex flex-col gap-3 px-5'>
          {nearestTimeSlots.length === 0 && (
            <p className='text-center text-secondary'>
              Unfortunately, there are no available time slots for this service
              ASAP. Please schedule an appointment.
            </p>
          )}
          <Button
            variant='yellow'
            className='py-4'
            onClick={handleAsapClick}
            disabled={nearestTimeSlots.length === 0}
          >
            {t('order.asap')}
          </Button>
          <Button
            variant='yellowStroke'
            className='py-4'
            onClick={() => navigate('/schedule')}
          >
            {t('order.schedule')}
          </Button>
        </div>
      ) : (
        <div className='flex flex-col gap-3 px-5'>
          <Button children={'s'} className='py-4' skeleton={true} />
          <Button children={'s'} className='py-4' skeleton={true} />
        </div>
      )}
    </Wrapper>
  );
};

export default ServicePage;
