import React, { useState, useRef, useCallback, useEffect } from 'react';
import Webcam from 'react-webcam';
import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router';
import ReactDOM from 'react-dom';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { toggleModal } from 'redux/modalSlice';
import { getTypedStorageItem } from 'utils/storage';
import { BASE_URL } from 'api/api';

import { RiNumber1, RiNumber2, RiNumber3 } from 'react-icons/ri';
import { FaCircleCheck } from 'react-icons/fa6';

import { Button, Wrapper } from 'ui';
import CameraModal from 'modals/CameraModal';

import PhotoExample1 from 'images/main-page/example1.jpg';
import PhotoExample2 from 'images/main-page/example2.jpg';
import PhotoExample3 from 'images/main-page/example3.jpg';

const getGuideContent = (currentStep: number) => {
  switch (currentStep) {
    case 1:
      return {
        icon: (
          <RiNumber1
            className='rounded-full border border-accent p-2 text-accent'
            size={60}
          />
        ),
        photoUrl: PhotoExample1,
        title: 'The first example',
        description: 'Squinting',
      };
    case 2:
      return {
        icon: (
          <RiNumber2
            className='rounded-full border border-accent p-2 text-accent'
            size={60}
          />
        ),
        photoUrl: PhotoExample2,
        title: 'The second example',
        description: 'Normal expression',
      };
    case 3:
      return {
        icon: (
          <RiNumber3
            className='rounded-full border border-accent p-2 text-accent'
            size={60}
          />
        ),
        photoUrl: PhotoExample3,
        title: 'The third example',
        description: 'Smiling',
      };
    default:
      return null;
  }
};

// Utility function to convert base64 to File
const base64ToFile = (base64String: string, filename: string): File => {
  const arr = base64String.split(',');
  const matchResult = arr[0].match(/:(.*?);/);
  const mime = matchResult ? matchResult[1] : '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const BotoxPhotoPage = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [firstPhoto, setFirstPhoto] = useState<string | null>(null);
  const [secondPhoto, setSecondPhoto] = useState<string | null>(null);
  const [hasCameraPermission, setHasCameraPermission] = useState(true);
  const [photoSent, setPhotoSent] = useState(false);

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const webcamRef = useRef<Webcam>(null);

  const sendPhotos = useCallback(
    async (firstPhoto: string, secondPhoto: string, thirdPhoto: string) => {
      const formData = new FormData();
      const photos = [
        { data: firstPhoto, name: 'firstPhoto.jpg' },
        { data: secondPhoto, name: 'secondPhoto.jpg' },
        { data: thirdPhoto, name: 'thirdPhoto.jpg' },
      ];

      photos.forEach(({ data, name }) => {
        if (data) {
          const file = base64ToFile(data, name);
          formData.append('files', file);
        }
      });

      formData.append('mediaType', 'photo');
      await fetch(`${BASE_URL}specialists/addPhotosToGallery`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${getTypedStorageItem('gotouAccessToken')}`,
        },
      });
    },
    [],
  );

  const capturePhoto = useCallback(() => {
    dispatch(toggleModal({ modalType: 'camera' }));
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      if (currentStep === 1) setFirstPhoto(imageSrc);
      if (currentStep === 2) setSecondPhoto(imageSrc);
      setCurrentStep((prevStep) => prevStep + 1);

      if (currentStep === 3 && firstPhoto && secondPhoto && imageSrc) {
        sendPhotos(firstPhoto, secondPhoto, imageSrc);
        setPhotoSent(true);
      }
    }
  }, [currentStep, dispatch, firstPhoto, secondPhoto, sendPhotos]);

  useEffect(() => {
    const checkCameraPermission = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        setHasCameraPermission(true);
      } catch (error) {
        setHasCameraPermission(false);
      }
    };

    checkCameraPermission();
  }, []);

  const handleCloseModal = () => {
    dispatch(toggleModal({ modalType: 'camera' }));
  };

  const handleNavigateOrder = () => {
    navigate(`/orders/${id}`, { replace: true });
  };

  const guideContent = getGuideContent(currentStep);

  return (
    <Wrapper flexColumnSpaceBetween>
      {photoSent ? (
        <>
          <div className='flex flex-col items-center'>
            <FaCircleCheck
              className='mb-9  rounded-full p-5 text-accent'
              size={144}
            />
            <h1 className='mb-2 text-center text-lg'>
              The photo was successfully sent to the cosmetologist
            </h1>
            <p className='text-center text-md text-secondary'>
              The cosmetologist will prepare as best as possible to meet you
            </p>
          </div>
          <Button
            variant='yellow'
            className='w-full py-4'
            onClick={handleNavigateOrder}
          >
            OK
          </Button>
        </>
      ) : (
        <>
          <div className='mb-5 flex flex-col gap-5'>
            <div>
              <h1 className='mb-4 text-center text-lg'>
                In order for the cosmetologist to prepare to meet with you,
                please send 3 photos of your face, in the exact format as shown
                in the example below
              </h1>
              <p className='text-center text-md text-secondary'>
                It will take a few minutes, but it will help to speed up the
                duration of the procedure at the beautician
              </p>
            </div>
            {guideContent && (
              <div className='flex flex-col items-center gap-6'>
                <div className='flex items-center gap-2'>
                  {guideContent.icon}
                  <p className='text-lg text-secondary'>{guideContent.title}</p>
                </div>
                <div className='rounded-[20px] border border-secondary p-4'>
                  <motion.img
                    key={guideContent.photoUrl}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3, ease: 'easeOut' }}
                    src={guideContent.photoUrl}
                    alt='Example how to take a selfie'
                    className='mb-3 aspect-[29/24] object-contain'
                  />
                  <p className='text-center text-lg'>
                    {guideContent.description}
                  </p>
                </div>
              </div>
            )}
          </div>

          <div>
            <Button
              variant='yellow'
              className='w-full py-4'
              onClick={() => dispatch(toggleModal({ modalType: 'camera' }))}
            >
              Take photo
            </Button>
            {!hasCameraPermission && (
              <Button
                variant='text'
                className='mx-auto w-full py-1 text-base'
                onClick={handleNavigateOrder}
              >
                Skip sending photos
              </Button>
            )}
          </div>
        </>
      )}

      {ReactDOM.createPortal(
        <CameraModal
          handleCloseModal={handleCloseModal}
          webcamRef={webcamRef}
          hasCameraPermission={hasCameraPermission}
          capturePhoto={capturePhoto}
          description={guideContent?.description || ''}
        />,
        document.getElementById('root') as Element,
      )}
    </Wrapper>
  );
};

export default BotoxPhotoPage;
