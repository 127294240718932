import React from 'react';
import { Toggle } from 'ui';
import PersonIcon from 'images/emoji/Face.svg';
import PortfolioIcon from 'images/emoji/Media.svg';
import MoneyIcon from 'images/emoji/(Money).svg';
import StarIcon from 'images/emoji/(star).svg';
import DiamondIcon from 'images/emoji/(Verified specialist).svg';
import DurationIcon from 'images/emoji/Hours.svg';
import BreakIcon from 'images/emoji/Weekend.svg';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { toggleModal } from 'redux/modalSlice';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type SpecialistStatsProps = {
  rank: number;
  reviewsNumber?: number;
  imagesAmount?: number;
  price?: string;
  duration?: number;
  name?: string;
  showBreakeOption?: boolean;
  portfolioUid?: string;
  reviewsUid?: string;
  showLeaveReview?: boolean;
  nameSubtitle?: string;
  isLoading?: boolean;
};

const icons: { [key: string]: string } = {
  PersonIcon,
  PortfolioIcon,
  MoneyIcon,
  StarIcon,
  DiamondIcon,
  DurationIcon,
  BreakIcon,
};

const SpecialistStats = ({
  rank,
  imagesAmount,
  price,
  duration,
  name,
  showBreakeOption,
  portfolioUid,
  reviewsUid,
  nameSubtitle,
  showLeaveReview = true,
  isLoading = false,
}: SpecialistStatsProps) => {
  const { t } = useTranslation();
  const { role } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const stats = [
    {
      icon: 'PersonIcon',
      title: name,
      subtitle: nameSubtitle ? nameSubtitle : t('name'),
      condition: !!name,
    },
    {
      icon: 'DurationIcon',
      title: t('order.duration'),
      subtitle: `${duration} ${t('minutes')}`,
      condition: duration !== undefined,
    },
    {
      icon: 'StarIcon',
      title:
        rank === 0
          ? t('order.specialist_no_rating')
          : `${rank} ${t('order.specialist_found.rating')}`,
      subtitle: (
        <div className='flex'>
          {role !== 'specialist' && showLeaveReview && (
            <span
              className='mr-2 cursor-pointer underline hover:no-underline'
              onClick={() => {
                dispatch(toggleModal({ modalType: 'clientLeaveReview' }));
              }}
            >
              {t('order.client.leave_review')}
            </span>
          )}

          {reviewsUid ? (
            <Link
              to={`/reviews/${reviewsUid}`}
              className='underline hover:no-underline'
            >
              {t('order.specialist_found.watch_reviews')}
            </Link>
          ) : (
            ''
          )}
        </div>
      ),
      condition: true,
    },
    {
      icon: 'DiamondIcon',
      title: t('order.specialist_found.verificated_person'),
      subtitle: t('order.specialist_found.telephone_number'),
      condition: true,
    },
    {
      icon: 'PortfolioIcon',
      title: t('order.specialist_found.portfolio'),
      subtitle: imagesAmount
        ? `${imagesAmount} ${t('order.specialist_found.images_cnt')}`
        : t('order.specialist_found.no_images'),
      condition: imagesAmount !== undefined,
      onClick: () =>
        portfolioUid &&
        (pathname === '/specialist/profile' || imagesAmount) &&
        navigate(`/specialist/portfolio/${portfolioUid}`),
    },
    {
      icon: 'MoneyIcon',
      title: `${price}`,
      subtitle: t('order.specialist_found.total_amount'),
      condition: price !== undefined,
    },
    {
      icon: 'BreakIcon',
      title: 'Break',
      subtitle: 'Choose breaks or a day off',
      condition: showBreakeOption,
      toggle: true,
    },
  ];

  return (
    <ul className='flex flex-col gap-[13px]'>
      {stats.map(({ icon, title, subtitle, condition, toggle, onClick }, i) =>
        condition ? (
          <li key={icon + i} className='flex items-start gap-3'>
            {isLoading ? (
              <div className='aspect-[162/48] h-11 flex-grow animate-pulse bg-gray-400' />
            ) : (
              <>
                {icon && (
                  <img
                    src={icons[icon]}
                    alt='icon'
                    height={20}
                    width={20}
                    className='mt-1 select-none xs:h-4 xs:w-4'
                  />
                )}
                <div>
                  <p>{title}</p>
                  {subtitle && (
                    <div
                      className={`text-secondary ${
                        onClick &&
                        'cursor-pointer select-none underline hover:no-underline'
                      }`}
                      onClick={onClick}
                    >
                      {subtitle}
                    </div>
                  )}
                </div>
                {toggle && <Toggle text={''} />}
              </>
            )}
          </li>
        ) : null,
      )}
    </ul>
  );
};

export default SpecialistStats;
