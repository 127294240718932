import React from 'react';
import SettingsList, { Settings } from 'components/Settings/SettingsList';
import { SectionHeader, Wrapper } from 'ui';
import { useAppSelector } from 'hooks/useAppSelector';
import { toggleModal } from 'redux/modalSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Logo from 'images/icons/logo.svg';

const SupportPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { role } = useAppSelector((state) => state.user);
  const text = role === 'client' ? [t('support.text_3')] : [t('help_finding')];

  const supportSettings = (): Settings[] => [
    {
      name: t('support.email_us'),
      onClick: () => {
        dispatch(toggleModal({ modalType: 'supportEmail' }));
      },
    },
    {
      name: t('delete_account.title'),
      onClick: () => navigate(`/profile/delete`),
    },
  ];

  return (
    <Wrapper flexColumnSpaceBetween>
      <SectionHeader title={t('menu.support')} />
      <section className='flex flex-col items-center gap-7'>
        <img src={Logo} alt='logo' height={42} width={42} />
        <div className='flex flex-col gap-3'>
          <h3 className='text-center text-lg'>{t('support.text_1')}</h3>
          <p className='text-center text-secondary'>
            {t('support.text_2')} <br />
            {text.map((line, i) => (
              <span key={`${line}-${i}`}>
                {line}
                <br />
              </span>
            ))}
          </p>
        </div>
      </section>
      <SettingsList
        title=''
        wrapperClassName='border-t border-gray-3 pt-9'
        settings={supportSettings()}
      />
    </Wrapper>
  );
};

export default SupportPage;
