import React from 'react';
import CardWithIcon from './CardWithIcon';
import MapEmodji from 'images/emoji/World Map.svg';
import StarEmodji from 'images/emoji/(star).svg';
import LikeEmodji from 'images/emoji/like.png';
import MediaEmodji from 'images/emoji/Media.svg';
import { Button } from 'ui';
import SectionTitle from './SectionTitle';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router';
import { setUserRole } from 'redux/userSlice';
import { useTranslation } from 'react-i18next';

const cards = (t: any) => [
  {
    title: t('businessPage.benefits.cards1.title'),
    description: t('businessPage.benefits.cards1.description'),
    icon: LikeEmodji,
  },
  {
    title: t('businessPage.benefits.card2.title'),
    description: t('businessPage.benefits.card2.description'),
    icon: MediaEmodji,
  },
  {
    title: t('businessPage.benefits.card3.title'),
    description: t('businessPage.benefits.card3.description'),
    icon: StarEmodji,
  },
  {
    title: t('businessPage.benefits.card4.title'),
    description: t('businessPage.benefits.card4.description'),
    icon: MapEmodji,
  },
];

const BenefitsSection = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogin = () => {
    dispatch(setUserRole('specialist'));
    navigate('/login');
  };

  return (
    <section
      className='section-ellipse-top main-page-wrapper  py-12'
      id='advantages'
    >
      <div className='content relative flex flex-col items-center'>
        <SectionTitle
          title={t('businessPage.benefits.title')}
          description={t('businessPage.benefits.description')}
        />

        <div className=' mt-14 grid gap-12 sm:grid-cols-2'>
          {cards(t).map((card, i) => (
            <CardWithIcon key={card.title + i} {...card} />
          ))}
        </div>
        <Button
          onClick={handleLogin}
          variant='yellowBlackStroke'
          className='mt-10 flex-1 sm:mt-16'
        >
          {t('businessPage.buttons.start')}
        </Button>
      </div>
    </section>
  );
};

export default BenefitsSection;
