import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FooterSection from 'components/Main/FooterSection';
import HeroSection from 'components/MainSpecialist/HeroSection';
import GuideSection from 'components/MainSpecialist/GuideSection';
import BenefitsSection from 'components/MainSpecialist/BenefitsSection';
import ReviewsSection from 'components/MainSpecialist/ReviewsSection';
import ReadyStartSection from 'components/MainSpecialist/ReadyStartSection';
import MainSpecialistHeader from 'components/MainSpecialist/MainSpecialistHeader';

import 'styles/main-specialist.css';

const MainSpecialistPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  const navigationLinks = () => [
    {
      title: t('businessPage.navigation.advantages'),
      href: 'advantages',
    },
    {
      title: t('businessPage.navigation.reviews'),
      href: 'reviews',
    },
  ];

  return (
    <main className='main-page-gap flex flex-col items-center bg-[#F6F5F4] font-sans'>
      <MainSpecialistHeader />
      <HeroSection />
      <p className='text-4xl leading-[3.8rem] md:self-center md:text-center md:text-[3rem]'>
        {t('businessPage.slogan.text1')}{' '}
        <span className='italic text-accent'>
          {t('businessPage.slogan.higlighted1')}
        </span>
        {t('businessPage.slogan.text2')}{' '}
        <span className='italic text-accent'>
          {t('businessPage.slogan.higlighted2')}{' '}
        </span>
        {t('businessPage.slogan.text3')}{' '}
        <span className='italic text-accent'>
          {t('businessPage.slogan.higlighted3')}{' '}
        </span>
        {t('businessPage.slogan.text4')}
      </p>
      <BenefitsSection />
      <GuideSection />
      <ReviewsSection />
      <ReadyStartSection />
      <FooterSection links={navigationLinks()} role='client' />
    </main>
  );
};

export default MainSpecialistPage;
