export const convertMsToMin = (ms: number) => {
  return Math.floor(ms / 60000);
};

export const convertHoursToMs = (hours: number) => {
  return hours * 3600000;
};

export const convertMinToMs = (min: number) => {
  return min * 60000;
};

export const convertMsToTime = (ms: number) => {
  return new Date(ms).toLocaleTimeString([], {
    timeStyle: 'short',
  });
};

// Helper function to convert HH:MM time to minutes past midnight
export const convertTimeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

export const THIRTY_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000;
