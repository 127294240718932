import React from 'react';
import AppScreenShot from 'images/main-page/mocap_1.png';
import AppScreenShotHair from 'images/main-page/Group.png';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import ReasonItem from './ReasonItem';

const WhyChooseUsSection = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isBotoxPage = pathname.includes('botox');

  return (
    <section className='main-page-wrapper flex max-w-[1440px] flex-col items-center'>
      <h3 className='mb-10 text-[2.81rem] leading-[4.219rem]'>
        {t('hairdresserPage.whyChoose.heading')}
      </h3>
      <div className='flex flex-col md:flex-row md:justify-between md:gap-24'>
        <img
          src={isBotoxPage ? AppScreenShot : AppScreenShotHair}
          alt='Screenshot of app search specialist'
          loading='lazy'
          className='mx-auto w-3/4 object-contain md:w-[40%]'
        />
        <ul className='flex flex-col gap-14'>
          {(
            t('hairdresserPage.whyChoose.reasons', {
              returnObjects: true,
            }) as string[]
          ).map((title, i) => (
            <ReasonItem key={`${title}-${i}`} order={i + 1} title={title} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default WhyChooseUsSection;
